import { getRatingPercent } from 'components/applications/pip/helpers/PIP.helpers';
import clsx from "clsx";
import Score from '../Score';
import Style from './RatingsChart.module.scss';

    
function RatingsChart({ average_score=0,  starDist = {}, reviewSection=false}) { 
    const ratingPercent = getRatingPercent(starDist);

    const goToReviews = () => {
        location.href = `#productReviews`
    }
  

    return (
        <div className={clsx(reviewSection?Style.reviewSection : Style.generalSection)}>

            <div className={Style.box}>
                <span className={Style.avg}>
                    {average_score.toFixed(1)}
                </span>

                <span className={Style.total}>
                    out of 5
                </span>

                <button data-tracking="readreviews" onClick={goToReviews} className={Style.btn}>
                    Read reviews
                </button>
            </div>
    
            <div className={Style.chart}>
                {Object.keys(starDist).sort().reverse().map((key, i) => {                        
                    return (
                        <div className={Style.starCount} key={"score_" + i}>
                            <Score score={key} percent={ratingPercent[key]} className={ Style.ratingChartScore} />
                            
                            <small className={Style.numberCount}>
                                ({starDist[key]})
                            </small>
                        </div>
                    )
                })}
            </div>
        </div>
   
    );
}


export default RatingsChart;