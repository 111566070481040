import Skeleton from "components/feedback/Skeleton";
import Style from "./OptionsPreloader.module.scss"


function OptionsPreloader(){
    return(
        <div className={Style.optionsBlock}>
            <div className={Style.block}>
                <div className={Style.name}>
                    <Skeleton width="50px" height="50px" variant="circle" /> &nbsp; &nbsp;
                    <Skeleton width="75%" height="20px" className={Style.text} />
                </div>
                
                <div className={Style.options}>
                    <div className={Style.row}>
                        <Skeleton width="80px" height="80px" className={Style.option} />
                        <Skeleton width="80px" height="80px" className={Style.option} />
                        <Skeleton width="80px" height="80px" className={Style.option} />
                        <Skeleton width="80px" height="80px" className={Style.option} />
                        <Skeleton width="80px" height="80px" className={Style.option} />
                    </div>

                    <div className={Style.row}>
                        <Skeleton width="80px" height="80px" className={Style.option} />
                        <Skeleton width="80px" height="80px" className={Style.option} />
                        <Skeleton width="80px" height="80px" className={Style.option} />
                        <Skeleton width="80px" height="80px" className={Style.option} />
                        <Skeleton width="80px" height="80px" className={Style.option} />
                    </div>
                </div>
            </div>
        </div>
    )
}


export default OptionsPreloader;