

/**
 * Get the JSON Object to send to cart
 * @param {string} type - define the type of object
 * @param {string} qty 
 * @param {string} productId 
 * @param {string} variantId
 * @param {object} options
 * @returns JSON Object
*/

export default function getCartBody(type, { 
    qty, 
    productId, 
    variantId, 
    options = {}, 
    customerId = 0
}){
    
    if( type === "cartBody" ){
        const body = {
            "customer_id": parseInt(customerId),
            "line_items": [
                {
                    "quantity": qty,
                    "product_id": productId,
                    "variant_id": variantId,
                }
            ]
        }

        if( options && Object.values(options).length ){     
            body.line_items[0]["option_selections"] = Object.values(options).map(item => {
                return {
                    "option_id": item.attribute,
                    "option_value": item.attributeValue,
                }
            })
        }

        return body;

        
    }else if( type === "itemBody"){
        return  {
            "customer_id": parseInt(customerId),
            "line_item": {
                "quantity": qty,
                "product_id": productId,
                "variant_id": variantId,
            }
        }
    }
    
}
