import getCartBody from './getCartBody';


/**
 * ATC Standard Single Product
 * @param {object} itemMutation - mutation hook 
 * @param {object} productData - product data
 * @param {string} customerId - customer id
 * @param {function} errorCallback - error callback
 * @param {function} confirmationCallback - confirmation callback
 * @param {boolean} buyNow - buy now flag
 * @param {array} sentAddons - sent addons
 */

export default function atcSingleProduct({ 
    productData, 
    itemMutation, 
    customerId, 
    errorCallback = () => null, 
    confirmationCallback = () => null, 
    buyNow = false,
    sentAddons = [],
    cartBody = null
}){

    const { qty, productId, variantId, options } = productData ?? {};

    const productBody = cartBody ?? getCartBody("cartBody", { 
        qty, 
        productId, 
        variantId, 
        options,
        customerId
    })
    
    itemMutation.mutate({type: "add", productBody, buyNow }, 
        {
            onSuccess: (data) => { 
                if( 
                    data?.response?.status === 404 ||
                    data?.response?.status === 308
                ){
                    errorCallback(data.response, productData) 
                    return null;
                }
                                        
                confirmationCallback({ 
                    productAdded: [ ...sentAddons, `${productId}:${qty}` ],
                    cartData: data
                });
            },

            onError: async (err) => {
                errorCallback(err, productData)
            }
        }
    )
}