import { ProductOptionContext } from "components/applications/pip/contexts/ProductOptionContext";
import { useContext, useEffect, useState } from "react";


export default function useSetNextOption({ product_id }){
    const [ state, _ ] = useContext(ProductOptionContext);
    const [ next, setNext ] = useState(1)


    useEffect(() => {
        if( state?.setReceiverControl?.step && state?.setReceiverControl?.product_id === product_id ){
            const receiverStep = state?.setReceiverControl?.step;

            setNext(receiverStep + 1)
        }

    }, [ state?.setReceiverControl ])

    return next

}