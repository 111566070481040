import { getCustomerCheckoutUrl } from "services/bigcommerce/getCustomerCheckoutUrl";
import { beginCheckoutAnalytics } from "components/applications/cart/helpers/beginCheckoutAnalytics";
import { setRejoinerCheckout } from "thirdparty/Rejoiner/Rejoiner.service";
import makeQueryString from "lib/Utils/makeQueryString";
import { lazy, useContext } from "react";
import { usePathname, useRouter } from "next/navigation";
import { GlobalContext } from "contexts/GlobalContext";
import { ProductOptionContext } from "components/applications/pip/contexts/ProductOptionContext";
import * as Sentry from '@sentry/nextjs';
import { LocalStorage, storageKeys } from "services/LocalStorage.service";
import PersistedCart from "../helpers/persistedCart";
import { OfferContextProvider } from "thirdparty/Extend/context/OfferContext";

const CartWidget = lazy(() => import("../components/CartWidget", { ssr: false }))


export default function useAtcCallbacks({ 
    buyNow, 
    redirectToCart, 
    prodCat,
    contextState,
    feedback = null,
    setLoading = () => null,
    setSuccess = () => null,
    setError = () => null,
    add = () => null,
}){

    const [ global, globalDispatch ] = useContext(GlobalContext)
    const [ state, dispatch ] = useContext(ProductOptionContext)
    const router = useRouter();
    const pathName = usePathname()



    /**
     * Triggers confirmation modal/interstitial page or on buy now, redirect to checkout
     * @param {String} productAdded - `{product}:{qty}`
     * @param {object} cartData - cart response
     */

    async function triggerConfirmation({ productAdded, cartData }){
        setSuccess(true)
        setLoading(true)

        if( buyNow || productAdded?.buyNow ){
            const redirectUrl = cartData?.redirect_urls?.checkout_url ?? cartData?.data?.redirect_urls?.checkout_url

            beginCheckoutAnalytics(cartData, global?.user?.user_id,  prodCat)

            if( cartData?.customer_id > 0){
                await getCustomerCheckoutUrl(cartData).catch(err => { setLoading(false) })
            
            }else if(redirectUrl){
                router.push(redirectUrl);
            }


        } else {
            setRejoinerCheckout(cartData, prodCat);

            if( redirectToCart ){
                router.push(`/cart?id=${cartData?.id ?? cartData.data?.id}`)

            }else{

                // need to clean up the buy now flag at this point
                let cleanProductAdded = []

                if( !Array.isArray(productAdded) && Object.hasOwn(productAdded, "buyNow") ){
                    delete productAdded.buyNow;
                    cleanProductAdded = Object.keys(productAdded).map(key => productAdded[key]).join(",")
                
                }else if( Array.isArray(productAdded) ){
                    cleanProductAdded = productAdded.join(",")

                }else{
                    cleanProductAdded = productAdded
                }


                // need to let the query update before opening the drawer which has a URL dependency
                globalDispatch({
                    type: "setWidget",
                    data: {
                        isOpen: true,
                        component: (
                            <OfferContextProvider>
                                <CartWidget 
                                    type="widget"
                                    {...{ cart: cartData?.data ?? cartData }} 
                                />
                            </OfferContextProvider>
                        )
                    }
                })


                globalDispatch({
                    type: "setAndApplyUrlParams",
                    data: { 
                        product: cleanProductAdded,
                        continue: pathName,
                        id: cartData?.id ?? cartData.data?.id,
                        isSet: contextState === "sets"
                    }
                })


                    
            }
            
        }  
    }





    /** 
     * on success, do these things
     * @param {String} productAdded - `{product}:{qty}`
     * @param {object} cartData - cart response
    */

    function confirmationCallback({ productAdded, cartData }){   
        if( state.protectionPlans ){
            dispatch({
                type: "atc",
                data: {
                    status: "done",
                    productAdded,
                    cartData,
                    buyNow,
                    redirectToCart,
                    prodCat,
                }
            })

        }else{
            triggerConfirmation({ productAdded, cartData })

            setTimeout(resetFeedbackState, 3000)
        }
    }
    




    // Resets all of the feedback state objects
    function resetFeedbackState(){
        setLoading(false)
        setSuccess(false)
        setError(false)

        dispatch({
            type: "atc",
            data: null
        })
    }
           




    // on Error, do this
    async function errorCallback(response, productData){
        if( response?.data ){
            const responseBody = JSON.parse(response?.data?.responseBody)

            // if the Big Commerce cart ID is bad, refresh the cart behind the scenes
            if( responseBody?.title.includes("Cart of ID")){
                const persistedCart = new PersistedCart()

                // remove old cart with bad ID
                LocalStorage.remove(storageKeys.cart)

                // refresh the existing cart using cart persistence
                persistedCart.refreshCart(itemMutation, () => {
                    // add this failed product to the new cart
                    add(productData, global.user?.external_customer_id)
                })


                try{
                    Sentry.withScope(scope => {
                        scope.setTag("cart", "retry");
                        scope.setFingerprint(productData);
                        scope.setLevel("info");
                        scope.setExtra("options", productData?.options);
                        scope.setExtra("response", response);
                        Sentry.captureMessage(response);
                    });
                    
                }catch(e){}


            }else{
                const reactToast = await import("react-toastify");
                reactToast.toast.error("Oh no. That didn't quite work. Please try adding that product to cart again.")  

                try{
                    Sentry.withScope(scope => {
                        scope.setTag("cart", "error");
                        scope.setFingerprint(productData);
                        scope.setLevel("error");
                        scope.setExtra("options", productData?.options);
                        scope.setExtra("response", response);
                        Sentry.captureException(response);
                    });

                }catch(e){}

                console.log(response)

                if(feedback) feedback.setError(true)

                setTimeout(resetFeedbackState, 1000)
            }   
        }
    }
    

    
    

    return {
        errorCallback,
        resetFeedbackState,
        confirmationCallback,
        triggerConfirmation
    }
}