import whilst  from "async/whilst";
import * as Sentry from '@sentry/nextjs';
import getCartBody from "./getCartBody";
import isObject from "lib/Utils/isObject";


/**
 * ATC Addon Options
 * @param {Object} addonOptions - addon options
 * @param {Object} itemMutation - item mutation
 * @param {String} customerId - customer id
 * @param {Function} errorCallback - error callback
 * @param {Function} sentAddons - sent addons
 * @param {Function} resolve - async resolver
 * @param {Function} reject - async rejection
 * @param {Number} qty - quantity
 */

export default function atcAddonOptions({ 
    addonOptions, 
    itemMutation,
    customerId, 
    errorCallback,
    sentAddons = () => null,
    resolve = () => null,
    reject = () => null,
    qty = 1
}){
    const sentProducts = [];
    const addonProducts = [];
    let index = 0;


    Object.keys(addonOptions).forEach(key => {
        if(  addonOptions[key].title !== "No Thanks" ){
            if( key !== "cover" ){
                addonProducts.push(addonOptions[key])
    
            }else{
                addonOptions[key].productOptions.forEach(product => {
                    addonProducts.push({
                        ...product
                    })
                })
            }
        }
    })



    whilst(
        (cb) => { cb(null, index < addonProducts.length); },
        (callback) => {
            const { quantity, variant_id, attributeValue, attribute, product_id, options } = addonProducts[index] ?? {};
        
            let opts = {}
            
            if (attributeValue && attribute){
                opts = {
                    covers: {
                        attributeValue,
                        attribute
                    }
                }
            }else if( Array.isArray(options) && options.length ){
                options.forEach((option, i) => {
                    opts = {
                        ...opts,
                        [`covers_${i}`]: {
                            attributeValue: option.attributeValue,
                            attribute: option.attribute
                        }
                    }
                })
            }

            
            // if this is a product cover multiply the quantity by the qty of the core product
            const addonQty = (isObject(opts) && (Object.hasOwn(opts, "covers") || Object.hasOwn(opts, "covers_1") )) ? parseInt(quantity) * qty : parseInt(quantity) 


            const productBody = getCartBody("cartBody", { 
                qty: addonQty, 
                variantId: parseInt(variant_id), 
                productId: product_id,
                options: opts,
                customerId
            })


            itemMutation.mutate({ type: "add", productBody },
                {
                    onSuccess: (data) => {
                        if( 
                            data?.response?.status === 404 || 
                            data?.response?.status === 308 
                        ){
                            errorCallback(data.response, addonProducts[index])
                        }

                        sentProducts.push(`${product_id}:${quantity}`)
                        callback(null, index);

                    },
                    onError: (err) => {
                        errorCallback(err, addonProducts[index])
                        callback(null, index);
                    }
                }
            )

            index++;

        }, (err) => {
            sentAddons(sentProducts)
            resolve()
        }
    );
}