import useSetNextOption from "custom_hooks/useSetNextOption";
import ErrorBoundary from "services/ErrorBoundary";
import CustomizeType from "../Options/CustomizeType";

import Style from "./Addon.module.scss"


function Options({ 
    options, 
    product_id,
    hideSwatch = "",
    stockedOptions = "",
    specialOrderOptions = "", 
}){
    const next = useSetNextOption({ product_id });

    return(
        <div className={Style.options}>
            <ErrorBoundary>
                <CustomizeType 
                    {...{ 
                        optionData: options, 
                        next, 
                        product_id,
                        contextState: "addons",
                        hideSwatch,
                        stockedOptions,
                        specialOrderOptions,
                    }}
                />
            </ErrorBoundary>
        </div>
    )
}


export default Options;