"use client"

import Style from './Ratings.module.scss';
import clsx from "clsx";
import RatingsChart from '../RatingsChart';
import ReviewStars from 'components/product/ReviewStars';
import { useGetProductReviews } from 'thirdparty/Yotpo/YotpoContext';
import { useEffect, useState } from 'react';

function Ratings({ product_id }) { 
    const getReviewData = useGetProductReviews(product_id, {page: 1, })
    const [ reviewData, setReviewData ] = useState(null)

    useEffect(() => {
        if( getReviewData?.data ){
            setReviewData(getReviewData.data)
        }

    }, [ getReviewData?.data ])


    const avgScore = reviewData?.average_score;
    const starDist = reviewData?.star_distribution;

    const goToReviews = () => {
        location.href = `#productReviews`
    }

    return (
        <>
            {avgScore ? (
                <div className={clsx(Style.block, Style.rqStars)}>
                    <ReviewStars 
                        average_score={avgScore} 
                        onlyStars
                    />

                    <div className={Style.rqSection} data-tracking={avgScore !== 0 ? "reviewsHover" : "reviewsClick"}>
                        <button
                            type="button"
                            onClick={(avgScore !== 0 && goToReviews) || null}                                
                            className={clsx(avgScore !== 0 && Style.hasRatings, Style.button)}                
                        >
                            Reviews and questions
                        </button>

                        {(avgScore !== 0) && (
                            <div className={clsx(Style.ratingHover)}>
                                <RatingsChart 
                                    average_score={avgScore} 
                                    starDist={starDist} 
                                />
                            </div>
                        )}
                    </div>
                            
                </div> 
            ):null} 
        </>
        
    );
}



export default Ratings;