/* eslint-disable react-hooks/exhaustive-deps */
import { ProductOptionContext } from 'components/applications/pip/contexts/ProductOptionContext'
import { isSelectedOption, selectedOption } from 'components/applications/pip/helpers/isSelectedOption.helper'
import sortObjectArrayByKey from 'lib/Utils/sortObjectArrayByKey'
import { lazy, useContext, useState, useEffect, useRef, Suspense } from 'react'
import OptionsPreloader from './OptionsPreloader'

const Swatches = lazy(() => import("components/applications/pip/components/Options/Swatches"))
const Dropdowns = lazy(() => import("components/applications/pip/components/Options/Dropdowns"))
const RadioButtons = lazy(() => import("components/applications/pip/components/Options/RadioButtons"))
const Protection = lazy(() => import("components/applications/pip/components/Options/Protection"))


function CustomizeType({ 
    optionData = [], 
    product_id = null, 
    errors = [], 
    next = null,
    showDisplayName = true,
    contextState = "options",
    isMobile,
    hideSwatch = "",
    stockedOptions = "",
    specialOrderOptions = "",
    productSetLength = null

}){
    const [ state, dispatch ] = useContext(ProductOptionContext);
    const [ options, setOptions ] = useState([])
    const optionRef = useRef(options)
   
    useEffect(() => {
        if( next > optionData.length ){
            setTimeout(() => {
                dispatch({
                    type: "readyToAddToCart",
                    data: true
                })

            }, 1000);
        }

    }, [ next ])


    
    // Keeps everything fresh
    useEffect(() => {
        if( Array.isArray(optionData) && optionData.length === 0 ) return;
        
        const sortedOptions = sortObjectArrayByKey(optionData, "sort_order")
        optionRef.current = sortedOptions;

        setOptions(sortedOptions)

    }, [ optionData ])



    // useEffect(() => {
    //     if( Array.isArray(options) && options.length === 0 ) return;

    //     options.forEach(item => {
    //         if (item?.type === "swatch" && item?.display_name.toLowerCase() === "test"){
    //             // auto select the no thanks option value and return null
    //             const reducerType = getUpdateReducerName(contextState)
    //             const noThanks = item?.values.filter(item => item?.label.includes("Thanks"))[0]
                
    //             dispatch({
    //                 type: reducerType,
    //                 data: {
    //                     key: item?.display_name,
    //                     title: "",
    //                     image: null,
    //                     price: 0,
    //                     attributeValue: noThanks?.option_id,
    //                     product_id: product_id
    //                 }
    //             })
        
        
    //             // notify receivers of the global update
    //             dispatch({ 
    //                 type: "setReceiverControl", 
    //                 data: {
    //                     key: item?.display_name,
    //                     title: "",
    //                     step: 1,
    //                     product_id: product_id
    //                 } 
    //             })
    //         }
    //     })

    // }, [ options ])

    

    return(
        <Suspense fallback={<OptionsPreloader/>}>
            {options.length === 0 && <OptionsPreloader/>}

            {options.map((item, index) => {
                const customized = {
                    item, 
                    length: item?.values.length,
                    productSetLength, 
                    isMobile, 
                    step: index + 1, 
                    product_id: product_id ?? item?.product_id, 
                    errors, 
                    contextState,
                    showDisplayName,
                    openState: (index + 1) === next,
                    isSelected: isSelectedOption({ contextState, state, item, product_id }),
                    selectedOption: selectedOption({ contextState, state, item, product_id })
                }


                // if we are a swatch and have less than 5 options
                if( item?.type === "swatch" && item?.display_name.toLowerCase() !== "test" ){
                    return(
                        <Swatches 
                            key={`${product_id}_${item?.option_group_id}`}
                            {...{ 
                                ...customized,
                                hideSwatch, 
                                stockedOptions, 
                                specialOrderOptions,
                            }}
                        />
                    )

                }else if (item?.type === "swatch" && item?.display_name.toLowerCase() === "test"){
                    return null
                }


                // if we are showing a single dropdown
                if( item?.type === "dropdown" ){
        
                    // and we are a long dropdown
                    if( Array.isArray(item.values) && item.values.length > 3 ){
                        return(
                            <Dropdowns 
                                key={item?.option_group_id}
                                {...customized}
                            />
                        )
                    }
                    

                    if( !item?.display_name.includes("Protective") ) {
                        // or if we are a short dropdown
                        return(
                            <RadioButtons 
                                key={item?.option_group_id}
                                {...customized}
                            />
                        )


                    }else {
                        return (
                            <Protection 
                                key={item?.option_group_id}
                                {...customized}
                            />
                        )
                    }

                }
            })}
        </Suspense>
    )
}



export default CustomizeType;