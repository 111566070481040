"use client"

import { setRejoinerProduct } from 'thirdparty/Rejoiner/Rejoiner.service';
import { ga4EventData } from 'thirdparty/Google/helpers/event.helpers';
import { useEffect } from 'react';
import { sendGTMEvent } from '@next/third-parties/google';


export function AddonAnalytics({ addons, brand, family, parentId, userId }){
    useEffect(() => {

        const obj = Object.entries(addons).map(([k],i) => ({
            item_name: addons[k]['name'],
            item_id: k || addons[k]['sku'],
            index: i,
            price: addons[k]['price'],
            affiliation: parentId,
            item_variant: addons[k]['variant_id'],
            quantity: addons[k]['quantity'],
            item_category: brand?.brandShortName || "", // pass pmt explicitly to avoid taking data from view_item event item_category
            item_category2: family || "",
            item_brand: brand?.name || "",
            item_list_name: "", // pass pmt explicitly to avoid taking data from view_item event item_list_name
            item_list_id: "" // pass pmt explicitly to avoid taking data from view_item event item_list_id
                                                        
        }));

        const view_item_lists = {
            value:0,
            item_list_id: "view_item_addons",
            item_list_name: "Addons On PDP",
            items: obj                           
        };
        sendGTMEvent({ event: 'reset_dl', ecommerce: null});
        sendGTMEvent({ event: 'view_item_list', ecommerce: {...view_item_lists, user_id: userId }}); 

    }, [
        brand?.brandShortName,
        brand?.name,
        family,
        parentId,
        userId,
        addons
    ])

    return null
}

export function GiftCardAnalytics({ items, userId }) {
    useEffect(() => {
        const obj =  items.map((amount, index) => ({
                                                    item_id: `gift_card_${amount}`, 
                                                    item_name: `Gift Card $${amount}`,
                                                    index: index,
                                                    price: amount,
                                                    quantity: 1,
                                                    item_category: "giftCertificates"
                            }));
        
        const view_item_lists = {
            value:0,
            item_list_id: "giftCertificates",
            item_list_name: "Gift Cards",
            items: obj.flat()                          
        };
       
       sendGTMEvent({ event: 'reset_dl', ecommerce: null });
       sendGTMEvent({ event: 'view_item_list', ecommerce: { ...view_item_lists, user_id: userId } });
        
    }, [
        items,
        userId
    ])

    return null
}


export function VariantAnalytics({ options, brand, family, parentId, userId }){
    useEffect(() => {
        const obj =  Object.entries(options).map(([k], i) => (
            options[k].map(variant => {
            return   {
                item_name: variant['name'],
                item_id: variant['product_id'],
                price: "",
                affiliation: parentId,
                item_variant: variant['variation_value'],
                quantity: "",
                item_category: brand?.brandShortName || "", // pass pmt explicitly to avoid taking data from view_item event item_category
                item_category2: family || "",
                item_brand: brand?.name || "",
                item_list_name:  variant['variation_label'], // pass pmt explicitly to avoid taking data from view_item event item_list_name
                item_list_id: "" // pass pmt explicitly to avoid taking data from view_item event item_list_id
                }
                
            })
        ));
        
        const view_item_lists = {
            value:0,
            item_list_id: "view_item_variants",
            item_list_name: "Variants on PDP",
            items: obj.flat()                          
        };
       
        sendGTMEvent({ event: 'reset_dl', ecommerce: null });
        sendGTMEvent({ event: 'view_item_list', ecommerce: { ...view_item_lists, user_id: userId } });
        
    }, [
        brand?.brandShortName,
        brand?.name,
        family,
        parentId,
        userId,
        options
    ])

    return null
}



function PIPAnalytics({ productData, user }){
    useEffect(() => {
        if( productData ){
            // GA4 data layer
            try{
                const viewItem = ga4EventData(productData, 'view_item');
                sendGTMEvent({ event: 'reset_dl', ecommerce: null});
                sendGTMEvent({ event: 'view_item', ecommerce: {...viewItem, user_id: user?.user_id }});

                setRejoinerProduct(productData)
            }catch(err){}
        }   

    }, [
        productData,
        user?.user_id
    ])


    return null
}




export function addToCartAnalytics({
    qty,
    productId,
    variantId,
    name,
    price,
    brand,
    family,
    categories,
    parentId,
    buyNow,
    userId,
    contextState
}){
    const cat = (contextState === 'addons') ? { "item_category":'Addons'} : (categories.length ? {"item_category": categories.map(c => c.name).join(', ')} : {})
    
    const buy_now = buyNow ? { "buy_now": buyNow } : {};

    const atc_prod = {
        "currency":  "USD",
        "value": price,
        ...buy_now, //added event pmt as custom dimension to filter out atc vs buynow
        "items": [
            {
                "item_name": name,
                "price": price,
                "quantity": qty,
                "item_id": productId,
                "item_variant": variantId,
                ...cat,
                "item_brand": brand,
                "item_category2": family,
                "affiliation" : parentId
            }
        ]                
    };

    sendGTMEvent({ event: 'reset_dl', ecommerce: null});
    sendGTMEvent({ event: 'add_to_cart', ecommerce: { ...atc_prod, user_id: userId } });
}


export default PIPAnalytics;