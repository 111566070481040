import { validateOptions } from "components/applications/pip/helpers/PIP.helpers";
import { useCart } from "./useCart";
import { useContext } from "react";
import { ProductOptionContext } from "components/applications/pip/contexts/ProductOptionContext";
import { GlobalContext } from "contexts/GlobalContext";
import atcAddonOptions from "../helpers/atcAddonOptions";
import atcBatchProducts from "../helpers/atcBatchProducts";
import atcSingleProduct from "../helpers/atcSingleProduct";
import atcCustomProduct from "../helpers/atcCustomProduct";
import useAtcCallbacks from "./useAtcCallbacks";
import useButtonFeedback from "components/forms/Button/useButtonFeedback";


export function useAddToCart({ 
    contextState = null,
    qty, 
    productId,
    prodCat,
    variantId,  
    minimum_qty,
    maximum_qty,
    buyNow = false,
    type = null,
    redirectToCart = false
}){
    const [ state, dispatch ] = useContext(ProductOptionContext)
    const [ global, _ ] = useContext(GlobalContext)

    const feedback = useButtonFeedback()
    const { itemMutation } = useCart();



    /**
     * Universal Add to Cart function
     * @param {object} productData - Array or Object
     * @param {number} customerId - the current logged in customers id or default logged out id
     * @param {object} addonOptions - the current addon options
     * @param {string} type - the type of product being added
     * @param {boolean} buyNow - whether to redirect to cart after adding
     */

    const add = ( productData, customerId, addonOptions, type, buyNow ) => {
        const sentAddons = [];

        dispatch({
            type: "atc",
            data: {
                status: "started",
                productAdded: null,
                cartData: null
            }
        })

        // we have to add each addon option one-at-a-time to cart synchronously first then the core product
        const addonPromise = new Promise((resolve, reject) => {
            if( addonOptions && Array.isArray(Object.keys(addonOptions)) && Object.keys(addonOptions).length ){
                atcAddonOptions({
                    addonOptions,
                    customerId,
                    itemMutation,
                    qty,
                    errorCallback,
                    sentAddons: (data) => sentAddons.push(data),
                    resolve,
                    reject
                })

            // No Addons? So resolve to add everything else
            }else{
                resolve()
            }
        })
        

        addonPromise.then(() => {
            // Add Product Sets To cart
            if( Array.isArray(productData) ){
                atcBatchProducts({
                    productData,
                    customerId,
                    itemMutation,
                    errorCallback,
                    confirmationCallback: (data) => {
                        feedback.setSuccess(true)
                        confirmationCallback(data)
                    },
                    buyNow,
                    sentAddons
                })

            // OR Add a Single Product to cart
            }else{
                atcSingleProduct({
                    productData,
                    itemMutation,
                    customerId,
                    errorCallback,
                    confirmationCallback: (data) => {
                        feedback.setSuccess(true)
                        confirmationCallback(data)
                    },
                    buyNow,
                    sentAddons
                })
            }

            // not sure if I have to have this hear, but it's here for now
            if( type === "custom" ){
                atcCustomProduct({
                    productData,
                    itemMutation,
                    errorCallback,
                    confirmationCallback: (data) => {
                        feedback.setSuccess(true)
                        confirmationCallback(data)
                    }
                })
            }
            
        })

    }




    const { confirmationCallback, resetFeedbackState, errorCallback } = useAtcCallbacks({ 
        add,
        buyNow, 
        prodCat, 
        contextState,
        redirectToCart, 
        feedback 
    });






    // add standard product to cart
    function addToCart(){
        feedback.setLoading(true)

        const areNotValid = validateOptions(
            {
                ...(state[contextState]?.[(contextState === "addons" || contextState === "sets") && productId] ?? state[contextState])
            },
        )

        const validMinimumQty = qty >= minimum_qty;
        const validMaximumQty = qty <= maximum_qty;


        if( areNotValid.length || !validMinimumQty || !validMaximumQty ){
            dispatch({
                type: "setErrorAttributes",
                data: [
                    ...areNotValid,
                    { 
                        validMinimumQty, 
                        validMaximumQty 
                    }
                ]
            })

            feedback.setError(true)
            setTimeout(resetFeedbackState, 1000)

        }else{
            const complexOptions = (contextState === "addons" || contextState === "sets") ? { ...state[contextState]?.[productId] } : state[contextState]

            add({ 
                    qty, 
                    productId, 
                    variantId, 
                    options: complexOptions,
                }, 
                global.user?.external_customer_id,
                state?.addonOptions,
                type,
                buyNow
            )


        }
    }




    // add full product set to cart
    async function addSetToCart(){
        feedback.setLoading(true)

        const erred = [];

        // validate selections
        Object.keys(state.sets).forEach(item => {
            
            const areNotValid = validateOptions(state?.sets?.[item])

            if( areNotValid.length ){
                erred.push(...areNotValid)
            }
        })


        if( Object.keys(erred).length ){
            dispatch({
                type: "setErrorAttributes",
                data: erred
            })

            feedback.setError(true)
            setTimeout(resetFeedbackState, 1000)

        }else{
            dispatch({
                type: "setErrorAttributes",
                data: []
            })

            
            const newSets = { ...state.sets };

            delete newSets.products;

            const productData = Object.keys(state?.sets?.products).map(product => {  
                return {
                    ...state.sets.products[product],
                    options: newSets.hasOwnProperty(product) ? Object.values(newSets[product]).map(opt => opt) : [],
                    product_id: product,
                };
            })


            // add set to cart
            add(
                productData,  
                global.user?.external_customer_id,
                state?.addonOptions,
                type,
                buyNow
            )

        }
    }




    return{
        add,
        addToCart,
        addSetToCart,
        loading: feedback.loading,
        success: feedback.success,
        err: feedback.err,
    }


}