import PropTypes from 'prop-types';
import Link from "next/link";
import clsx from "clsx";
import stripBrandFromName from "lib/Utils/stripBrandFromName";
import RenderHeading from 'components/blocks/RenderHeading';
import Flags from 'components/display/Flags';
import Style from './Header.module.scss';
import Ratings from 'components/product/Ratings';


function Header({ 
    productData, 
    isSubHeader = false,
    isTrade,
    className,
    showRating = true,
    byline = '',
    component = null
}){
    const productName = stripBrandFromName(productData?.brand?.name, productData?.name, productData?.brand?.brandShortName)

    return (
        <header className={clsx(Style.block, className)}>
            <div className={Style.cntr}>
                <div className={Style.heading}>  
                    <RenderHeading 
                        headingType={isSubHeader ? "2" : "1"}
                        useHeadingTypeStyle={false}
                        className={clsx(Style.heading, isSubHeader && Style.heading2 )}
                        element={isSubHeader ? "h2" : "h1"}
                    >
                        
                        {byline ? (
                            <cite className={clsx(Style.byline, Style.customByline)}>
                                {byline}
                            </cite>
                        ):(
                            <cite className={Style.byline}>
                                {productData?.brand?.slug ? (
                                    <>
                                        <Link
                                            className={Style.bylineLink}
                                            href={`/shop-all-brands/${productData?.brand?.slug.split("-2")[0]}`}
                                            title={`More products on ${productData?.brand?.name}'s brand page`}
                                        >
                                            {productData?.brand?.name}
                                        </Link>
                                    </>
                                ):(
                                    <>
                                        {productData?.brand?.name} 
                                    </>
                                )}
                            </cite>
                        )}        
                        
                        

                        {productData?.addon_uri ? (                            
                            <Link 
                                href={"/" + productData?.addon_uri} 
                                title={`Visit ${productName} for more information`} 
                                className={Style.headingLink}
                            >
                                {productName}
                            </Link>                           
                        ):(
                            <>
                               {productName} 
                            </> 
                        )}

                    </RenderHeading>
                </div>

                
                {(productData?.product_id || productData?.sku || showRating) && (
                    <div className={Style.detailCntr}>
                        {/* {!!productData?.reviews?.average_score && (
                            <div className={Style.detail}>
                                <Stars 
                                    reviews={productData?.reviews} 
                                    goToReviews={goToReviews}
                                />
                            </div>
                        )} */}
                        
                        {(productData?.product_id || productData?.sku) && (
                            <small className={Style.detail}>
                                {productData?.product_id && (
                                    <><strong>ID#:</strong>&nbsp;{productData?.product_id} &nbsp;&nbsp;</>
                                )}
                                {productData?.sku && (
                                    <><strong>SKU:</strong>&nbsp;{productData?.sku}</>
                                )}
                            </small> 
                        )}
                                    
                    
                        {showRating && (
                            <div className={Style.detail}>
                                <Ratings product_id={productData?.product_id}/>
                            </div>
                        )}
                    </div>
                )}


                <div className={Style.badgeCntr}>
                    <Flags 
                        {...{ isTrade }}
                        flags={{
                            low_inventory: productData?.custom_fields?.low_inventory,
                            sale_flag: productData?.custom_fields?.sale_flag,
                            cyber_sale_flag: productData?.custom_fields?.cyber_sale_flag,
                            black_friday_sale_flag: productData?.custom_fields?.black_friday_sale_flag,
                            cyber_monday_sale_flag: productData?.custom_fields?.cyber_monday_sale_flag,
                            custom_quick_ship_icon: productData?.custom_fields?.custom_quick_ship_icon, 
                            new_color: productData?.custom_fields?.new_color,
                            ss_new: productData?.custom_fields?.ss_new, 
                            rebate: productData?.custom_fields?.rebate,
                            new: productData?.categories && productData?.categories.some(cat => cat.name.toLowerCase().includes("new!")),
                            rebatePromo: productData?.pdp_promo_text && productData?.pdp_promo_text.toLowerCase().includes("rebate")
                        }} 
                    />
                </div>
            </div>
        
            {component}
        </header>
    );
}


Header.propTypes = {
    productData: PropTypes.object, 
    tearSheetRef: PropTypes.object, 
    isSubHeader: PropTypes.bool,
    uri: PropTypes.string
}


export default Header;