"use client"

/* eslint-disable react-hooks/exhaustive-deps */
import Style from './AddToCartButton.module.scss'
import PropTypes from 'prop-types';
import Loader from "components/feedback/Loader"
import Button from 'components/forms/Button';
import clsx from "clsx";
import { useAddToCart } from "components/applications/cart/hooks/useAddToCart";
import { useContext, useEffect } from 'react';
import { ProductOptionContext } from 'components/applications/pip/contexts/ProductOptionContext';
import scrollToLocation from 'lib/Utils/scrollToLocation';
import { addToCartAnalytics } from 'components/applications/analytics/PIPAnalytics';

function AddToCartButton({
    qty, 
    productId,
    refs,
    variantId, 
    className = "",
    isProductSet = false,
    contextState = "options",
    added = () => null,
    isDisabled,
    minimum_qty,
    maximum_qty,
    optionBoxRef = null,
    name,
    price,
    brand,
    family,
    categories = [],
    parentId = null,
    buyNow = false,
    userId = null,
    handleOnClick
}) {
    const prodCat = {[productId]: categories };
    const cart = useAddToCart({ 
        contextState,
        qty, 
        productId,
        prodCat,
        variantId,  
        minimum_qty,
        maximum_qty,
        buyNow,
    })
    
    const [ state, _ ] = useContext(ProductOptionContext);

    const add = () => {
        if( isProductSet ){
            cart.addSetToCart()
            
        }else{
            cart.addToCart()
        }

       
    }


    useEffect(() => {
        if( cart.err ){
            if( optionBoxRef?.current ){
                scrollToLocation(optionBoxRef?.current?.offsetTop + 300)
            }
        }
        
        if( cart.success ){
            added()
        }

        addToCartAnalytics({
            qty,
            productId,
            variantId,
            name,
            price,
            brand,
            family,
            categories,
            parentId,
            buyNow,
            userId,
            contextState
        })

    }, [ 
        cart.err, 
        optionBoxRef?.current, 
        cart.success 
    ])

      const handelOnClick = () => {
          add();

          if (handleOnClick && refs!== undefined){
            handleOnClick(refs);
          }
     };    


    return(
        <Button 
            color={buyNow ? null : "ocean" }
            size="full" 
            className={clsx(Style.block, className, state?.readyToAddToCart && Style.ready)} 
            onClick={handelOnClick}
            type="button"
            {...{ isDisabled }}
        >
            {(cart.loading || cart.success || cart.err) ? 
                <Loader 
                    isLoading={cart.loading} 
                    isSuccessful={cart.success} 
                    hasError={cart.err} 
                /> 
            : 
                buyNow ? "Buy Now" : "Add to Cart"
            }
        </Button>
    )
}


AddToCartButton.propTypes = {
    qty: PropTypes.number, 
    productId: PropTypes.number, 
    variantId: PropTypes.number, 
    isProductSet: PropTypes.bool,
    className: PropTypes.string,
    contextState: PropTypes.oneOf(['options', 'sets', 'addons']),
    added: PropTypes.func
}


export default AddToCartButton;