"use client"

import clsx from "clsx";
import Button from 'components/forms/Button';
import Link from "next/link";
import { useState } from "react";
import dynamic from 'next/dynamic'
import Grid from "components/layout/Grid";
import utils from 'styles/globals/utils.module.scss';
import Style from './WhiteGlove.module.scss'
import { IconScheduledDelivery } from "components/icons/IconScheduledDelivery";
import { IconDeliveryAssembly } from "components/icons/IconDeliveryAssembly";
import { IconPackagingRemoval } from "components/icons/IconPackagingRemoval";
import { IconWhiteGlove } from "components/icons/IconWhiteGlove";


const Modal = dynamic(() => import('components/feedback/Modal'))


function WhiteGlove(){
    const [ openModal, setModalOpen ] = useState(false)

    function open(){
        setModalOpen(true)
    }



    return (
        <>
            <div className={Style.block}>
                <IconWhiteGlove width="75" height="80" className={clsx(utils.me_1, Style.icon)} />

                <div className={clsx(utils.ms_1, utils.lh_sm)}>
                    <p className={Style.heading}>
                        White Glove Delivery Available
                    </p>
                    
                    <small> 
                        How does it work?&nbsp;
                        <Button type="button" color="transparent" variant="link" noAnimate onClick={open}>
                            Learn more
                        </Button>
                    </small>
                </div>
            </div>



            {openModal && 
                <Modal
                    size="lg" 
                    heading="How Does White Glove Delivery Work?"
                    shouldOpen={openModal} 
                    willClose={() => setModalOpen(false)}
                >
                    <Grid md={3} sm={1} className={utils.mt_6}>
                        <div className={clsx(utils.p_2, utils.d_flex, utils.flex_column, utils.align_items_center, utils.text_center)}>
                            <IconScheduledDelivery width="150" height="150" className={utils.mb_2} />
                            <h3 className={utils.fs_4}>
                                Scheduled Delivery Appointment
                            </h3>
                            <p >We first ship your order to a delivery company in your local area and they will work with you to schedule your White Glove service.</p>
                        </div>

                        <div className={clsx(utils.p_2, utils.d_flex, utils.flex_column, utils.align_items_center, utils.text_center)}>
                            <IconDeliveryAssembly width="150" height="150" className={utils.mb_2} />
                            <h3 className={utils.fs_4}>
                                Unpacking & Assembly
                            </h3>
                            <p >The local service provider will carefully unpack, inspect and assemble each item in your order, and place the item(s) where you want.</p>
                        </div>

                        <div className={clsx(utils.p_2, utils.d_flex, utils.flex_column, utils.align_items_center, utils.text_center)}>
                            <IconPackagingRemoval width="150" height="150" className={utils.mb_2} />
                            <h3 className={utils.fs_4}>
                                Packaging Removal & Disposal
                            </h3>
                            <p >All packaging like boxes, plastic, and shipping insulation will be removed and discarded for you.</p>
                        </div>
                    </Grid>
                    

                    <p className={clsx(utils.p_2, utils.text_center)}>
                        <Link href="/c/shipping/" title="learn more" className={utils.cta}>
                                Visit our shipping policies
                        </Link>
                    </p>
                </Modal>
            }
        </>
    );
}

export default WhiteGlove;