
/**
 * Generate "unique" alpha numeric id
 * @returns GUID
 */


export default function GenerateID(){ 
    return Math.random().toString(36).substring(2) + (new Date()).getTime().toString(36);
}
