

export function initAddonProducts(options, product_id){
    const obj = {
        [product_id]: {}
    };

    options.forEach((option, index) => {
        obj[product_id] = {
            ...obj[product_id],
            [option?.display_name]:{
                index,
                attribute: option.option_group_id,
                attributeValue: null
            }
        }
    });

    return obj
}