import axios from "axios";
import { LocalStorage, storageKeys } from "services/LocalStorage.service";


/** -----------------------------------------------------------------------
 * Yotpo Service
 * Fetches the Yotpo 3rd party service for product ratings
 * ------------------------------------------------------------------------ */
export default class YotpoService {

    /**
     * @param {object} options.product - object literal for product
     * @param {number} options.productId - this product id to associate reviews
     * @param {boolean} options.isProductPage - if on product page
     */

    constructor(options){
        this.settings = {
            opts: {
                client_id: process.env.NEXT_PUBLIC_yotpo_key,
                client_secret: process.env.NEXT_PUBLIC_yotpo_secret,
                grant_type: "client_credentials"
            },
            ...options,
        };

        if( options?.product_id ){
            this.createBody = {
                appkey: process.env.NEXT_PUBLIC_yotpo_key,
                sku: options.product_id,
                product_title: options.title,
                product_url: options.url,
                product_image_url: options.image,
                prevent_duplicate_review: true,
                utoken: this.token
            };
        }

        this.controller = new AbortController();

        this.token = this.fetchToken();
    }



    /** Search Reviews
     * Not sure if this works correctly...
     * https://apidocs.yotpo.com/reference#search-reviews
     */
    getKeywordReviews(term){
        let res = this.searchProductReviews({
                body: {
                    domain_key: `${this.settings.productId}`,
                    free_text_search: term,
                    per_page: 5
                },
                page: 1
            });

        res.then((data) => data);
    }
 
        




    /**
    * GET bulk response from Yotpo
    * @param {Array} productIdArray arry of product ids 
    */
    async fetchBulk(productIdArray){
        let body = this.buildBulkObject({
                products: productIdArray,
                method: "bottomline",
                pageNumber: 1,
                format: "json"
            }),
            res = this.postYotpoData("https://staticw2.yotpo.com/batch?", body);

        return res;
    }




    /**
     * Builds the yotpo object to send to yotpo for 
     * the updated ratings
     * @param {string}  args.method - fetching string
     * @param {array}   args.products - array of product id strings
     * @param {string}  args.free_text_search - searched keyword
    */

    buildBulkObject(args){
        let yotpoObj = {
            app_key: process.env.NEXT_PUBLIC_yotpo_key,
            methods: []
        };
        
        try{
            args.products.forEach( (element) => {
                let batchObj = {
                        method: args.method,
                        params: {
                            pid: element,
                            pictures_per_review: 10,
                            is_mobile: false
                        },
                        format: "json"
                    };

                yotpoObj.methods.push(batchObj);
            });

        }catch(err){}
        
        return yotpoObj;
    }



    // General fetching service to get products
    async postYotpoData(url = "", body, utoken = null){
        return await axios({
                url: url.includes("http") ? url : `https://api.yotpo.com${url}${utoken ? `?utoken=${utoken}` : ""}`,
                method: "POST",
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    ...this.createBody,
                    ...body
                }),
                signal: this.controller.signal
            })
            .then(res => res)
    }



    // POST Customer Review Data
    async createReview(body){
        return await this.postYotpoData("/reviews/dynamic_create", body);
    }



    // POST Customer Question Data
    async createQuestion({body, utoken}){
        return await this.postYotpoData("/questions", body, utoken);
    }



    // Get Yotpo Auth Token via session storage then POST
    async fetchToken(){
        const hasToken = LocalStorage.getStorage(storageKeys.yotpo.token);

        if( hasToken ){
            return Object.hasOwn(hasToken, "access_token") ? hasToken.access_token : hasToken;
        }

        return await this.postYotpoData("/oauth/token", this.settings.opts)
            .then(response => {
                LocalStorage.setStorage(storageKeys.yotpo.token, response?.data.access_token);
                return response?.data.access_token;
            })
    }



    /**
     * Fetches reviews for a given product
     * @param {number} productId 
     * @param {number} args.page - page number 
     * @param {number} args.page - star rating 
     */

    async getProductReviews(productId, args){
        let query = this.setQueryPrams(args);

        return axios.get(`${process.env.NEXT_PUBLIC_yotpo_api}/v1/widget/${process.env.NEXT_PUBLIC_yotpo_key}/products/${productId}/reviews.json${query}`,{
                signal: this.controller.signal
            })
            .then(res => res?.data?.response)
    }



    /**
     * POST keyword based searched reviews
     * @param {number} args.page - page number 
     * @param {number} args.page - star rating 
     * @param {object} args.body - the search body 
     */

    async searchProductReviews(args){
        const query = this.setQueryPrams(args);
        const opts = JSON.stringify(args.body);
        const url = `/v1/reviews/${process.env.NEXT_PUBLIC_yotpo_key}/filter.json${query}`;

        return await this.postYotpoData(url, opts);
    }



    // fetches yotpo questions for a given product
    async getProductQuestions(productId){
        return await axios.get(`${process.env.NEXT_PUBLIC_yotpo_api}/products/${process.env.NEXT_PUBLIC_yotpo_key}/${productId}/questions`,{
                signal: this.controller.signal
            })
            .then(response => response?.data?.response)
    }

    

    // get a user product review by a yotpo review id
    async getReviewById(reviewId){
        const token = await this.token ?? await this.fetchToken()

        return await axios.get(`${process.env.NEXT_PUBLIC_yotpo_api}/reviews/${reviewId}?utoken=${token}`,{
                signal: this.controller.signal
            })
            .then(data => data?.data?.response?.review)
    }



    /**
     * Gets the user uploaded product images for a given reviewed product
     * Get Yotpo Media
     * https://apidocs.yotpo.com/reference/get-media
     * https://api.yotpo.com/v1/apps/aS8rMIONwGgNbx1ATQmUtKY173Xk5HHc75qGrnuq/images/export?utoken=wbHSh2jGWfExl6vfFBl700ktxEt7VpNej9XaKZqZ&source=review&media_type=image&per_page=100
     * @returns 
    */

    async getUserProductImages(page = 1){
        try{
            const token = await this.token ?? await this.fetchToken()
            
            return await axios.get(`${process.env.NEXT_PUBLIC_yotpo_api}/v1/apps/${process.env.NEXT_PUBLIC_yotpo_key}/images/export?utoken=${token}&source=review&media_type=image&per_page=100&page=${page}`,{
                    signal: this.controller.signal
                })
                .then(data => data?.data?.response)
                .catch(err => console.log(err))

        }catch(err){
            console.log(err);
        }
    }



    // filter out images by product id
    parseProductUGCbyID(ugcImages, productId){
        return ugcImages?.filter(ugc => ugc.tagged_products[0] === productId.toString());
    }




    /**
     * Sets most used query parameters
     * @param {number} args.page - page number 
     * @param {number} args.star - star rating 
     * @param {number} args.sort - sort rating  
     */

    setQueryPrams(args){
        let params = "?per_page=5";

        if( args ){
            params += `${args?.page ? `&page=${args.page}` : ''}`;
            params += `${args?.star ? `&star=${args.star}` : ''}`;
            params += `${args?.sort ? `&sort=${args.sort}` : ''}`;
        }

        return params;
    }



    // gets the distribution % for each rating
    getDistributionPercentage(totalReviews, reviewDistribution){
        let distro = (reviewDistribution/totalReviews) * 100;
        return distro.toFixed();
    }



    // gets the sentiment score to determine the recommendation percentage
    getSentimentScore(reviewsArray){
        let avg = 0;

        reviewsArray.forEach(element => { 
            avg = element?.sentiment + avg; 
        });

        avg = (avg/reviewsArray.length) * 100;
        
        return avg.toFixed();
    }


    /**
     * Import Images
     * https://apidocs.yotpo.com/reference/import-images
     * - We will need to upload the images somewhere on our server and then give them a URL
     * 
     * 
     const options = {
        method: 'POST',
        headers: {Accept: 'text/plain', 'Content-Type': 'application/json'},
        body: JSON.stringify({
            images: [
            {
                user_name: 'Joe User',
                user_email: 'joe@email.com',
                tagged_products: ['298686079', '1'],
                image_url: 'https://upload.storage.org/image_server/user_generated/d/dd/image.jpg',
                caption: 'Big',
                creation_date: '2018-01-31'
            },
            {
                user_name: 'Jane User',
                user_email: 'jane@email.com',
                image_url: 'https://upload.storage.org/image_server/user_generated/d/dd/image.jpg'
            }
            ]
        })
        };

        fetch('https://api.yotpo.com/v1/widget/app_key/images/create_batch', options)
        .then(response => response.json())
        .then(response => console.log(response))
        .catch(err => console.error(err));
     */


        /**
         * Get Media for UGC
         * https://apidocs.yotpo.com/reference/get-media
         * 
            import axios from "axios";

            const options = {
            method: 'GET',
            url: 'https://api.yotpo.com/v1/apps/app_key/images/export',
            params: {page: '1', per_page: '10', utoken: ''},
            headers: {Accept: 'application/json', 'Content-Type': 'application/json'}
            };

            axios.request(options).then(function (response) {
            console.log(response.data);
            }).catch(function (error) {
            console.error(error);
            });


            OR get by Product
            https://apidocs.yotpo.com/reference/product-images


            import axios from "axios";

            const options = {
            method: 'GET',
            url: 'https://api.yotpo.com/v1/widget/app_key/albums/product/product_id',
            params: {page: '1', per_page: '10'},
            headers: {Accept: 'application/json', 'Content-Type': 'application/json'}
            };

            axios.request(options).then(function (response) {
            console.log(response.data);
            }).catch(function (error) {
            console.error(error);
            });



         */


}