"use client"

/* eslint-disable react-hooks/exhaustive-deps */
import Style from "./Addon.module.scss"
import utils from 'styles/globals/utils.module.scss';

import Image from "next/image";
// import stripBrandFromName from "lib/Utils/stripBrandFromName";
import { useContext, useEffect, useRef, useState } from "react";
import ProductPrice from "components/product/ProductPrice";
import { ProductOptionContext } from "components/applications/pip/contexts/ProductOptionContext";
import { initAddonProducts } from "./Addon.helpers";
import { calculateUpCharges, getTradePrice } from "components/applications/pip/helpers/PIP.helpers";
import Options from "./Options";
import AddToCartButton from "components/product/AddToCartButton";
import QuantityButton from "components/product/QuantityButton";
import ErrorBoundary from "services/ErrorBoundary";
import GridColumn from "components/layout/GridColumn";
import Grid from "components/layout/Grid";
import { GetProductData } from "services/pip/ProductContext.service";
import Highlights from "../Details/Highlights";
import Link from "next/link";
import Header from "../Header";
import { setRejoinerProduct } from "thirdparty/Rejoiner/Rejoiner.service";
import { blurImage } from 'lib/Constants/blurImage';
import { toast } from 'react-toastify'


function Item({ 
    sku, 
    image, 
    name, 
    brand, 
    price, 
    variant_id, 
    minimum_qty, 
    maximum_qty,
    product_uri,
    minimum_order_qty_per_unit = null,
    rrp,
    options, 
    product_id,
    reviews,
    family,
    parentId = null,
    userId=null,
    isTrade = false
 }){
    const [ qty, setQty ] = useState(1);
    const [ newPrice, setPrice ] = useState(price * minimum_qty)
    const [ state, dispatch ] = useContext(ProductOptionContext);
    const [ addon, setAddon ] = useState(null)
    const item_Ref = useRef()


     // tracks price based on desired quantity
    useEffect(() => {
        const total = price * qty;

        if( isTrade ){
            const tradePrice = getTradePrice(total, addon?.custom_fields?.trade_discount)
            setPrice(tradePrice)

        }else{  
            setPrice(total)
        }

    }, [ 
        qty, 
        isTrade,
        addon?.custom_fields?.trade_discount 
    ])



    // sets any addons options
    useEffect(() => {
        setRejoinerProduct({ product_id, name, images:image, price, brand:brand?.name, product_uri, rrp })

        if( Array.isArray(options) && options.length ){
            const obj = initAddonProducts(options, product_id)

            dispatch({ 
                type: "setAddons", 
                data: obj
            })
        }

        GetProductData(product_uri).then(response => {
            setAddon(response)
        })
        
    }, [])



    // calculates if there is an addon option
    useEffect(() => {
        if(Array.isArray(options) && options.length){
            const hasUpCharges = options.some(option => state.addons?.[product_id]?.[option?.display_name]?.attributeValue !== null )
            
            if( Object.values(state.addons).length && hasUpCharges ){
                const totalUpCharges = calculateUpCharges({ 
                    qty, 
                    options: Object.values(state?.addons?.[product_id]),
                    price: price
                })

                setPrice(totalUpCharges)
            }
        }

    }, [ state.addons ])



    const itemRef = useRef();

    useEffect(() => {
        if( itemRef?.current?.offsetTop ){
            dispatch({
                type: "setRef",
                data: {
                    id: sku,
                    offsetTop: itemRef?.current?.offsetTop
                }
            })
        }

    }, [ itemRef ])

    const handleOnClick = (item_ref) => {

        if ((item_ref.current.querySelector('input[type="radio"]') !=null)  && !(item_ref.current.querySelector('input[type="radio"]').checked)) {
            toast.error(`Please choose a customization!`); 
        }
        else  {
            item_ref.current.querySelectorAll('input[class^=Quantity]')[0].focus();
        }   
    };

     return(
            <Grid ref={itemRef} element="form">
                <GridColumn start={1} end={6} className={Style.imageHeading}>
                    <Link 
                        href={"/" + product_uri} 
                        title={`Visit ${name}`} 
                        className={Style.headingLink}
                    >
                        <Image
                            className={Style.image} 
                            alt={name}
                            src={image}
                            width={500}
                            height={500}
                            placeholder="blur"
                            blurDataURL={blurImage}
                        />
                    </Link>
                </GridColumn>

                <GridColumn start={6} end={12} className={Style.content}>
                    <Header 
                        className={Style.contentHeading}
                        isSubHeader
                        productData={{
                            name,
                            brand,
                            sku,
                            product_id,
                            "addon_uri" : product_uri
                        }}
                    />


                    <Highlights 
                        type="basic"
                        data={[
                            addon?.custom_fields?.featured_highlight ?? "",
                            addon?.custom_fields?.highlight_1 ?? "",
                            addon?.custom_fields?.highlight_2 ?? "",
                            addon?.custom_fields?.highlight_3 ?? "",
                            addon?.custom_fields?.highlight_4 ?? ""
                        ]}
                    />



                    <p className={Style.priceBlock}>
                        <ProductPrice 
                            rrp={addon?.retail_price}
                            price={newPrice} 
                            className={Style.price} 

                        />

                        {( addon?.custom_fields?.minimum_order_qty_per_unit && qty === 1 ) ? (
                            <small>
                                /per {addon?.custom_fields?.minimum_order_qty_per_unit}
                                <em>&nbsp;— with a {minimum_qty} {addon?.custom_fields?.minimum_order_qty_per_unit} minimum purchase</em>
                            </small>
                        ):(
                            <small className={utils.text_muted}>
                                {!isTrade ? "with Free Shipping on orders over $200" : "trade price"}
                            </small> 
                        )}
                    </p>



                    {Array.isArray(options) && (
                        <ErrorBoundary>
                            <Options 
                                {...{ options, product_id }} 
                                hideSwatch={addon?.custom_fields?.hideswatch}
                                stockedOptions={addon?.custom_fields?.stocked_options}
                             specialOrderOptions={addon?.custom_fields?.special_order_options}
                              ref={item_Ref}
                            />
                        </ErrorBoundary>
                    )}
                    

                    <div className={Style.atcBlock}>
                        <QuantityButton 
                            value={qty} 
                            qty={(num) => setQty(num)}
                            min={minimum_qty}
                            max={maximum_qty}
                        />


                        <AddToCartButton 
                            {...{ 
                                qty, 
                                item_Ref, 
                                refs:itemRef,
                                variant_id, 
                                minimum_qty: minimum_qty ?? 1,
                                maximum_qty: maximum_qty ?? 999,
                                name, 
                                price, 
                                family, 
                                brand: brand?.name, 
                                parentId 
                         }}
                            handleOnClick={handleOnClick}
                            userId={userId}
                            productId={parseInt(product_id)}
                            contextState="addons"
                            added={async () => {
                                // if we are using the ATC Drawer we don't need this addon confirmation
                                const reactToast = await import("react-toastify");
                                reactToast.toast.success("Your Addon was added to cart!")
                            }}
                        />
                    </div>
                </GridColumn>
            </Grid>
    )
}



export default Item;