import PropTypes from "prop-types";
import clsx from "clsx";

import Style from './Score.module.scss';


function Score({ score, percent=null, className }){
    return(
        <>
            {score ? (
                <span 
                    role="banner"
                    className={clsx(Style.rating, className)} 
                    style={{"--rating": percent ?? score}} 
                    aria-label={`Rating of ${score} out of 5.`} 
                />
            ):null}
        </>
    )
}


Score.propTypes = {
    reviews: PropTypes.object,
    showIfEmpty: PropTypes.bool
}


export default Score;