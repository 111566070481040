import ToolTip from "components/help/ToolTip";
import { useToolTips } from "components/help/ToolTip/useToolTips";


function DeliveryTip(){
    const { toolTips } = useToolTips({ facets: ["cart_lead_time"] })

    return(
        <ToolTip 
            type="info"
            text={toolTips?.["cart-lead-time"]}
        />
    )
}

export default DeliveryTip;