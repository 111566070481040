"use client"

/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import { IconQuickShip } from 'components/icons/IconQuickShip';
import { IconRegularShip } from 'components/icons/IconRegularShip';
import clsx from 'clsx';

import Style from './LeadTime.module.scss';
import DeliveryTip from './DeliveryTip';

function LeadTime({ leadTime, leadTime2, optionData, className, customLeadTime }){
    const [ message, setMessage ] = useState("")
    const [ isQuickShip, setQuickShip ] = useState(false);
    const [ hasSpecialOrder, setSpecialOrder ] = useState(false)

    const messageRef = useRef(message)


    useEffect(() => {
        let msg = `${leadTime ?? ""} ${leadTime2 ?? ""} ${customLeadTime ?? ""}`.trim();
        messageRef.current = msg;

        setMessage(msg)

        return () => {
            setMessage("")
        }
        
    }, [ leadTime, leadTime2, customLeadTime ])


    
    useEffect(() => {
        if( Array.isArray(optionData) && optionData.length ){
            for (let i = 0; i < optionData.length; i++) {
                if( optionData[i]?.values.includes("Special Order") ){
                    let msg = leadTime.find(item => item.value.includes("Special Order"))
                    messageRef.current = msg;

                    setMessage(msg)
                    setSpecialOrder(true);
                    break;
                }
            }
        }

    }, [ optionData, leadTime, leadTime2 ])




    useEffect(() => {
        setQuickShip(message.toLowerCase().includes("ships next business day") )
        
        return () => {
            setQuickShip(false)
        }

    }, [message])







    return(
        <div className={clsx(Style.block, className)} role="banner" aria-label="Lead Time Banner">
            {isQuickShip ? 
                <IconQuickShip width="60" height="60" className={Style.icon} />
            :
                <IconRegularShip width="60" height="60" className={Style.icon} />
            }
           
            <div className={Style.text}>
                <p className={Style.rangeTime}>
                    {isQuickShip ? "Quick Ship" : "Lead Time" } 
                    <DeliveryTip />
                </p>

                <small className={Style.message} dangerouslySetInnerHTML={{ __html: message }} />
            </div>
        </div>
    )
}


LeadTime.propTypes = {
    leadTime: PropTypes.string,
    leadTime2: PropTypes.string,
    optionData: PropTypes.array,
    className: PropTypes.string
}


export default LeadTime;