import axios from "axios";
import { useEffect, useState } from "react";
import YotpoService from "thirdparty/Yotpo/Yotpo.service";


let yotpoService = null;


/**
 * POST Customer Review Data
 * @param {object} body 
 * @returns response object
 */

async function CreateReview(body){
    yotpoService = yotpoService ?? new YotpoService()
    const token = await yotpoService.token.then(data => data)

    return await yotpoService.createReview({ ...body, utoken: token }); 
}




/**
 * POST Customer Question Data
 * @param {object} body 
 * @returns response object
 */

async function CreateQuestion(body){
    yotpoService = yotpoService ?? new YotpoService()
    const token = await yotpoService.token.then(data => data)

    return await yotpoService.createQuestion({ body, utoken: token });   
}



/**
 * 
 * @param {number} productId 
 * @returns react query object
 */

async function useGetProductUGC(productId){
    yotpoService = yotpoService ?? new YotpoService()

    return await yotpoService.getUserProductImages()
}





/**
 * Fetches reviews for a given product
 * @param {number} productId 
 * @param {number} args.page - page number 
 * @param {number} args.star - star rating 
 */

function useGetProductReviews(productId, { page, sort }){
    const [ data, setReviewData ] = useState([])
    const [ items, setReviewItems ] = useState([])

    yotpoService = yotpoService ?? new YotpoService()

    useEffect(() => {
        yotpoService.getProductReviews(productId, { page, sort }).then(response => {
            if( response ){
                const reviews = response?.reviews

                setReviewData(response?.bottomline);
                setReviewItems(reviews)
            }
        })

    }, [ page, sort ])

    return{
        data,
        items
    }
}



/**
 * POST keyword based searched reviews
 * @param {number} args.page - page number 
 * @param {number} args.page - star rating 
 * @param {object} args.body - the search body 
 */

async function useSearchProductReviews(args){
    yotpoService = yotpoService ?? new YotpoService()

    const query = yotpoService.setQueryPrams(args);
    const opts = JSON.stringify(args.body);
    const url = `/v1/reviews/${process.env.NEXT_PUBLIC_yotpo_key}/filter.json${query}`;

    return await yotpoService.post(url, opts);
}




/**
 * fetches yotpo questions for a given product
 * @param {number} productId 
 * @returns react query object
 */

async function getProductQuestions(productId){
    yotpoService = yotpoService ?? new YotpoService()

    return await yotpoService.getProductQuestions(productId).then(response => response)
}



/**
 * Gets the user uploaded product images for a given reviewed product
 * @param {number} productId 
 * @returns response object
 */

function useGetUserProductImages(productId){
    return axios.get(`${process.env.NEXT_PUBLIC_yotpo_api}/v1/widget/${process.env.NEXT_PUBLIC_yotpo_key}/albums/product/${productId}`);
}



export {
    useGetProductUGC,
    getProductQuestions,
    useGetProductReviews,
    useGetUserProductImages,
    useSearchProductReviews,
    CreateQuestion,
    CreateReview
}