
/**
 * Checks to see if in the passed in parameter is an object
 * @param {object} obj 
 * @returns 
 */

export default function isObject(obj){
    return Object.prototype.toString.call(obj) === '[object Object]'
}
