import whilst  from "async/whilst";
import getCartBody from "./getCartBody";


/**
 * Batch Process adding products to cart via an array of products like sets
 * @param {object} productData - array of product objects
 * @param {string} customerId - customer id
 * @param {object} itemMutation - mutation hook
 * @param {function} errorCallback - error callback
 * @param {function} confirmationCallback - confirmation callback
 * @param {boolean} buyNow - buy now flag
 * @param {array} sentAddons - sent addons
 */

export default function atcBatchProducts({
    productData,
    customerId,
    itemMutation,
    errorCallback = () => null,
    confirmationCallback = () => null,
    buyNow = false,
    sentAddons = [],
}){
    const sentProducts = [];
    let cartData = null;
    let index = 0;
    let errors = [];


    // Async Loop here:
    // Big Commerce needs 1 at a time
    whilst(
        (cb) => { cb(null, index < productData.length); },
        (callback) => {

            sentProducts.push(`${productData[index].product_id}:${productData[index].quantity}`)
            
            const productBody = getCartBody("cartBody", { 
                qty: parseInt(productData[index].quantity), 
                productId: parseInt(productData[index].product_id), 
                variantId: parseInt(productData[index].variant_id), 
                options: productData[index]?.options,
                customerId: customerId
            })


            itemMutation.mutate({ type: "add", productBody, buyNow }, 
                {
                    onSuccess: (data) => {
                        if( 
                            data?.response?.status === 404 ||
                            data?.response?.status === 308
                        ){
                            errorCallback(data.response, productData[index])
                            errors.push(true)
                        }

                        cartData = data
                        callback(null, index);
                    },

                    onError: async (err) => {
                        errorCallback(err, productData[index])
                    }
                }
            )

            index++;

        }, (err) => {
            if( !errors.length ){

                // trigger cart confirmation showing...comma separated list of quantities and product ids
                confirmationCallback({ 
                    productAdded: [ sentAddons, sentProducts ].join(","),
                    cartData
                })
            }
        }
    );
}