import PropTypes from "prop-types";
import clsx from 'clsx';
import Style from './Highlights.module.scss';
import utils from 'styles/globals/utils.module.scss';
import DetailStyles from "../Details.module.scss";


function Highlights({ data, type }){
    return(
        <>
            {type === "basic" ? (
                 <ul className={Style.block}>
                    {data.map((item, index) => {
                        if( !item ){ return null }
                        
                        return(
                            <li className={clsx(Style.item, Style.basic)} key={`highlight_${index}`}>
                                {item}
                            </li>
                        )
                    })}
                </ul>

            ):(

                <>
                    <article data-tracking="pdp-item-highlights" className={DetailStyles.masonryBlock}>
                        <h3 className={clsx(utils.fs_4)}>
                            Highlights
                        </h3>

                        <ul className={Style.block}>
                            {data.map((item, index) => {
                                if( !item ){ return null }
                                
                                return(
                                    <li className={Style.item} key={`highlight_${index}`}>
                                        {item}
                                    </li>
                                )
                            })}
                        </ul>
                    </article>
                </>
            )
        }
        </>
    )
}


Highlights.propTypes = {
    data: PropTypes.array
}


export default Highlights;