
/**
 * Help function for Customize Type
 * @param {string} contextState - which context
 * @param {object} state - product option state context object
 * @param {object} item - product option item
 * @param {number} product_id 
 * @returns boolean
 */

export function isSelectedOption({ contextState, state, item, product_id }){
    if( contextState === "sets" || contextState === "cart" ){
        return !!state[contextState]?.[item.product_id]?.[item?.display_name]?.attributeValue;

    }else if( contextState === "addons"){
        return !!state[contextState]?.[product_id]?.[item?.display_name]?.attributeValue;

    }else{
        return !!state[contextState]?.[item?.display_name]?.attributeValue
    }   
}



/**
 * Help function for Selected Option in Customize Type
 * @param {string} contextState - which context
 * @param {object} state - product option state context object
 * @param {object} item - product option item
 * @param {number} product_id 
 * @returns state value
 */

export function selectedOption({ contextState, state, item, product_id }){
    if( contextState === "sets" || contextState === "cart" ){
        return  state[contextState]?.[item?.product_id]?.[item?.display_name]

    }else if( contextState === "addons"){
        return state[contextState]?.[product_id]?.[item?.display_name]
    
    }else{
        return state[contextState]?.[item?.display_name]
    }                          
}