import { IconMinus } from 'components/icons/IconMinus';
import { IconPlus } from 'components/icons/IconPlus';
import GenerateID from 'lib/Utils/generateID';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import Style from './QuantityButton.module.scss';
import { usePopper } from 'react-popper';
import { IconAlertTriangle } from 'components/icons/IconAlertTriangle';

const QuantityButton = React.forwardRef(({
    qty, 
    value, 
    id, 
    min = 1, 
    max = 999,
    notValidQty
}, ref ) => {
    const [ count, setCount ] = useState(null);
    const [ referenceElement, setReferenceElement ] = useState(null)
    const [ popperElement, setPopperElement ] = useState(null)
    const [ arrowElement, setArrowElement ] = useState(null)
    const [ notValid, setNotValid ] = useState(null);

    const controlID = useRef();


    function validateQty(value) {
        
        if (value < min) {
            setNotValid(`You must add at least ${min}`)
            return false

        } else if (value > max) {
            setNotValid(`You may not add more than ${max}`)
            return false

        } else {
            setNotValid(false)
            return true;
        }
    }



    const setQty = (value) => {
        let newValue = value.hasOwnProperty("currentTarget") ? value.currentTarget.value : (value >= min ? value : min);

        if (!validateQty(newValue)) {  return; }

        setCount(newValue);
        qty(newValue);
    }

    const prevCount = useRef(count)

    // resets the error
    useEffect(() => {
        let newValue = parseInt(value)

        if( count && prevCount.current ){
            validateQty(newValue)
        }

        setCount(newValue);

        controlID.current = GenerateID()
        
    }, [ value, min, max, count ])



    useEffect(() => {
        if( Array.isArray(notValidQty) && notValidQty.length ){
            const  qtyValidation = notValidQty.find(item => item.hasOwnProperty("validMinimumQty") || item.hasOwnProperty("validMaximumQty") )

            if( qtyValidation ){
                for( const key in qtyValidation ){
                    if (qtyValidation[key] === false && key === "validMinimumQty") {
                        setNotValid(`You must add at least ${min}`)
                        break;
                        
                    } else if (qtyValidation[key] === false && key === "validMaximumQty") {
                        setNotValid(`You may not add more than ${max}`)
                        break;

                    }else{
                        setNotValid(false)
                        break;
                    }
                }
            }
        } 

    }, [ notValidQty, min, max ]) 



    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: "bottom",
        modifiers: [
            { 
                name: 'arrow',
                options: {
                    element: arrowElement
                }
            }
        ]
    })


    return  (
        <label className={Style.block} htmlFor={`quantity_${id ?? controlID?.current}`} ref={setReferenceElement}>
            <button 
                type="button" 
                name="decrement" 
                aria-label="decrement"
                className={Style.toggleControl} 
                onClick={() => setQty(parseInt(count) - 1)}
            >
                <IconMinus className="icon-minus" width="20" height="20" />
            </button>

            <input 
                ref={ref}
                type="number" 
                className={Style.control}
                onChange={(e) => setQty(e)} 
                onFocus={(e) =>setQty(e)}
                required 
                id={`quantity_${id ?? controlID?.current}`}
                aria-label={`quantity input ${id ?? controlID?.current}`}
                value={count ?? ""} 
                min={min} 
                max={max} 
                pattern="[0-9]+" 
            />

            <button 
                type="button" 
                name="increment" 
                aria-label="increment"
                className={Style.toggleControl} 
                onClick={() => setQty(parseInt(count, 10) + 1)}
            >
                <IconPlus className="icon-plus" width="20" height="20" />
            </button>

            {notValid && (
                <div 
                    ref={setPopperElement} 
                    style={styles.popper} 
                    {...attributes.popper} 
                    className={Style.popover}
                >
                    <strong className={Style.error}>
                        <IconAlertTriangle 
                            width="25" 
                            height="25" 
                            className={Style.stepIconError} 
                        />
                        {notValid}
                    </strong>

                    <div ref={setArrowElement} style={styles.arrow} className={Style.arrow}/>
                </div>
            )}
            
        </label>
    )

})

QuantityButton.displayName = 'QuantityButton';


QuantityButton.propTypes = {
    value: PropTypes.number,
    qty: PropTypes.func,
    id: PropTypes.string
};


export default QuantityButton;