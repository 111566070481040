"use client"

import { IconHelpOutline } from "components/icons/IconHelpOutline";
import { IconInfoOutline } from "components/icons/IconInfoOutline";
import Style from "./ToolTip.module.scss"
import { usePopper } from "react-popper";
import { useState } from "react";
import CloseBtn from "components/forms/CloseButton";
import PropTypes from 'prop-types';
import clsx from "clsx";



function ToolTip({ 
    type = "info", 
    text = "",
    manualClose = false,
    isInline = false,
    className = null
}){

    const [ referenceElement, setReferenceElement ] = useState(null)
    const [ popperElement, setPopperElement ] = useState(null)
    const [ show, setShown ] = useState(false)

    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: "bottom",
        modifiers: [
            {
                name: 'offset',
                options: {
                  offset: [-20, 0],
                },
            }
        ]
    })

    return(
        <span className={clsx(Style.block, isInline && Style.inline, className)}>
            <span 
                className={Style.control} 
                ref={setReferenceElement} 
                onMouseEnter={() => setShown(true)}
                onMouseLeave={() => { 
                    if( !manualClose ){ setShown(false) }
                }}
            >
                {type === "info" && (
                    <IconInfoOutline className={Style.icon} />
                )}

                {type === "help" && (
                    <IconHelpOutline className={Style.icon} />
                )}
            </span>


            {show && (
                <span 
                    ref={setPopperElement} 
                    style={styles.popper} 
                    {...attributes.popper} 
                    className={Style.popover}
                >
                    {manualClose && (
                        <CloseBtn 
                            width={20}
                            height={20} 
                            className={Style.closeBtn} 
                            shouldClose={() => setShown(false)}
                        />
                    )}

                    {typeof text === "string" && (
                        <span 
                            className={Style.text}
                            dangerouslySetInnerHTML={{ __html: text }} 
                        />
                    )}

                    {typeof text === "object" && (
                        <span className={Style.text}>
                            {text}
                        </span>
                    )}
                    
                </span>
            )}
            
        </span>
    )
}



ToolTip.prototype = {
    type: PropTypes.oneOf(["info", "help"]),
    text: PropTypes.string,
    manualClose: PropTypes.bool
}


export default ToolTip;
